import "../styles/Welcome.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useUser } from '../context/userContext';
import { useGame } from "../context/gameContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Modal from "react-bootstrap/Modal";

import { ReactComponent as IconSpunta} from "../assets/LIGA_APP_spunta.svg";
import { ReactComponent as IconArena} from "../assets/LIGA_APP_arena.svg";
import { ReactComponent as IconSfida} from "../assets/LIGA_APP_sfida.svg";
import { ReactComponent as IconBadge1} from "../assets/BADGE_1_icon.svg";
import { ReactComponent as IconBadge2} from "../assets/BADGE_2_icon.svg";
import { ReactComponent as IconBadge3} from "../assets/BADGE_3_icon.svg";
import { ReactComponent as IconBadge4} from "../assets/BADGE_4_icon.svg";
import { ReactComponent as IconBadge5} from "../assets/BADGE_5_icon.svg";

import { getGamingScore } from "../utils/gaming";

function Welcome(props) {
  // const { user } = useUser();
  const { gamePrizes, updateGamePrizes } = useGame();
  const { t } = props;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleCloseScanErrorModal = () => props.setShowScanErrorModal(false);
  const handleShowScanErrorModal = () => props.setShowScanErrorModal(true);

  const handleOpenScan = () => {
    if (props.scanner) props.scanner?.current?.start();
    navigate("/scan");
  };

  const handleOpenLigaFan = () => {
    navigate("/ligafan");
  };

  const handleOpenCampovolo = () => {
    navigate("/campovolo");
  };

  const tooltipPairing = (
    <Popover>
      <Popover.Body>
        {t("registration.disclaimer")}
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (props.games) {
      const gameSet = props.games.filter(gameMatch => gameMatch.gameId === "ligafan")[0];
      if (gameSet?.gamePoints) {
        const gamingScore = getGamingScore('ligafan', gameSet.gamePoints);
        if (gamingScore) updateGamePrizes("ligafan", gamingScore);
      }
    }
  }, [props.games]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="welcome-mirror">
              {props.scanResult ? (
                <>
                  {/* <i className="bi bi-check2-circle" /> */}
                  <i className="done"><IconSpunta /></i>
                  <h1>{t("welcome.title.paired.wearable")}</h1>
                  <p>{t("welcome.descr.paired.wearable")}</p>
                </>
              ) : (
                <>
                  <h1>
                    {t("welcome.title.wearable.to.pair")}
                    {/* <OverlayTrigger trigger="click" placement="bottom" rootClose="true" overlay={tooltipPairing}>
                      <Button className="popover-trigger">{t("welcome.title.wearable.to.pair")}*</Button>
                    </OverlayTrigger> */}
                  </h1>
                  <p>{t("welcome.descr.wearable.to.pair")}</p>
                </>
              ) }
            </div>
            {props.scanResult ? (
              <Button variant="secondary" disabled>
                {props.scanResult}
              </Button>
            ) : (
              // <Button variant="primary" onClick={handleShowModal}>
              //   {t("welcome.cta.wearable.to.pair")}
              // </Button>
              <Button variant="primary" onClick={handleOpenScan}>
                {t("welcome.cta.wearable.to.pair")}
              </Button>
            )}
            <Button className="btn-grid" variant="secondary" onClick={handleOpenLigaFan}>
              {/* <i className="bi bi-shield-fill" /> */}
              <i className="ci">
                {
                  (gamePrizes?.ligafan === "0.20" && <span style={{color: "#e30f6f"}}><IconBadge1 /></span>) ||
                  (gamePrizes?.ligafan === "20.40" && <span style={{color: "#0a71b3"}}><IconBadge2 /></span>) ||
                  (gamePrizes?.ligafan === "40.60" && <span style={{color: "#ec7703"}}><IconBadge3 /></span>) ||
                  (gamePrizes?.ligafan === "60.80" && <span style={{color: "#00892e"}}><IconBadge4 /></span>) ||
                  (gamePrizes?.ligafan === "80.100" && <span style={{color: "#e1001a"}}><IconBadge5 /></span>) ||
                  <IconSfida />
                }
              </i>
              {
                (gamePrizes?.ligafan === "0.20" && <span style={{color: "#e30f6f"}}>{t("game.ligafan.prize.0.20.name")}</span>) ||
                (gamePrizes?.ligafan === "20.40" && <span style={{color: "#0a71b3"}}>{t("game.ligafan.prize.20.40.name")}</span>) ||
                (gamePrizes?.ligafan === "40.60" && <span style={{color: "#ec7703"}}>{t("game.ligafan.prize.40.60.name")}</span>) ||
                (gamePrizes?.ligafan === "60.80" && <span style={{color: "#00892e"}}>{t("game.ligafan.prize.60.80.name")}</span>) ||
                (gamePrizes?.ligafan === "80.100" && <span style={{color: "#e1001a"}}>{t("game.ligafan.prize.80.100.name")}</span>) ||
                t("menu.ligafan.item")
              }
              <small>{gamePrizes?.ligafan ? t("menu.ligafan.item") : t("menu.ligafan.min")}</small>
            </Button>
            <Button className="btn-grid" variant="secondary" onClick={handleOpenCampovolo}>
              {/* <i className="bi bi-shield-fill" /> */}
              <i className="ci"><IconArena /></i>
              {t("menu.campovolo.item")}
              <small>{t("menu.campovolo.min")}</small>
            </Button>
            <Button variant="secondary" href="https://ligabue.com/barmario/link" target="_blank">
              <i className="bi bi-arrow-right" />
              {t("welcome.cta.barmario")}
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <h2 className="page-title">{t("info.campovolo.disclaimer.title")}</h2>
          <p className="page-subtitle">{t("info.campovolo.disclaimer.text")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOpenScan}>
            {t("welcome.cta.wearable.to.pair")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={props.showScanErrorModal} onHide={handleCloseScanErrorModal} centered>
        <Modal.Body>
          <p className="page-subtitle error">{t("welcome.wearable.error")}</p>
          <p className="page-subtitle">{t("registration.disclaimer")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { handleOpenScan(); handleCloseScanErrorModal();}}>
            {t("welcome.cta.wearable.to.pair")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Welcome;
