const validationUtils = {
  findFieldError(field, value, t) {
    const errors = {};
    const requiredFields = ["firstName", "lastName", "email", "password", "termsOfUse", "specificApproval", "privacyConsent"];
    if (!value && requiredFields.includes(field)) {
      errors[field] = t("formErrors.error.mandatoryField");
    } else if (value) {
      switch (field) {
        case "phoneNumber":
          if (!/^3[0-9]{8,9}$/.test(value))
            errors.phoneNumber = t("formErrors.error.invalidPhone");
          break;
        case "email":
          if (
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9+.-]+\.[a-zA-Z]{2,}$/.test(value)
          ) {
            errors.email = t("formErrors.error.invalidEmail");
          }
          break;
        case "password":
          if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/.test(value)) {
            errors.password = t("formErrors.error.invalidPassword");
          }
          break;
          case "termsOfUse":
          if (!value) {
            errors.termsOfUse = t("formErrors.error.termsOfUse");
          }
          break;
          case "specificApproval":
          if (!value) {
            errors.specificApproval = t("formErrors.error.specificApproval");
          }
          break;
          case "privacyConsent":
          if (!value) {
            errors.privacyConsent = t("formErrors.error.privacyConsent");
          }
          break;
        default:
          break;
      }
    }
    return errors;
  },

  findFormErrors(fields, t) {
    const errors = {};
    Object.keys(fields).forEach((field) => {
      const fieldError = this.findFieldError(field, fields[field], t);
      if (fieldError) Object.assign(errors, fieldError);
    });
    return errors;
  },
};

export default validationUtils;
