import "../styles/Loader.scss";
import Spinner from 'react-bootstrap/Spinner';

function Loader() {
  return (
    <div className="loader">
        <Spinner animation="border" variant="light" role="status">
            <span className="visually-hidden">…</span>
        </Spinner>
    </div>
  );
}

export default Loader;
