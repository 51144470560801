export const getGamingScore = (game, gamePlay) => {
    let gameSolution;
    let gameScore = 0;
    let gameScoreId = 0;

    if (game === 'ligafan') {
        gameSolution = window.atob("MSwzLDIsMiwyLDIsMSwzLDMsNA==").split(',').map((item) => {
            return parseInt(item);
        });
        if (gamePlay?.length === gameSolution.length) {
            gamePlay?.forEach((answer, index) => {
                if (answer === gameSolution[index]) gameScore++;
            });
            // console.log("______ gamePlay", gameScore);
            const gamePercent = (100 * gameScore) / gameSolution?.length;
            // console.log("______ gamePlay", gamePercent + "%");
            if (gamePercent >= 80) {
                gameScoreId = "80.100";
                // console.log("______ setGameScore 80.100");
            } else if (gamePercent >= 60) {
                gameScoreId = "60.80";
                // console.log("______ setGameScore 60.80");
            } else if (gamePercent >= 40) {
                gameScoreId = "40.60";
                // console.log("______ setGameScore 40.60");
            } else if (gamePercent >= 20) {
                gameScoreId = "20.40";
                // console.log("______ setGameScore 20.40");
            } else {
                gameScoreId = "0.20";
                // console.log("______ setGameScore 0.20");
            }
        }
    }
    
    return gamePlay?.length === gameSolution.length ? gameScoreId : null;
};