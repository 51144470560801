import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import "../styles/Registration.scss";
import { useNavigate } from "react-router-dom";
import useFormValidation from "../hooks/useFormValidation";
import { registerUser, verifyOtpAndLogin } from "../utils/api";
import { useUser } from "../context/userContext";

import termsAndConditionsDoc from "../assets/001-Termini_e_Condizioni_WebApp_la_Notte_di_Certe_Notti.pdf";
import specificApprovalDoc from "../assets/002-Approvazione_specifica_ai_sensi_degli_artt_1341_e_1342_cc_WebApp_la_Notte_di_Certe_Notti.pdf";
import privacyPolicyDoc from "../assets/Privacy_Policy_Web_App_Campovolo_2025.pdf";

const Registration = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ajaxError, setAjaxError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [validated, setValidated] = useState(false);

  const { t } = props;

  const navigate = useNavigate();
  const { updateUser } = useUser();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const initialFields = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsOfUse: false,
    specificApproval: false,
    privacyConsent: false,
    firstPartyMarketingConsent: false,
    thirdPartyMarketingConsent: false,
  };
  const {
    values = {},
    errors = {},
    handleFieldChange,
    validateForm,
    setHasSubmitted,
  } = useFormValidation(isSuccess ? { otp: "" } : initialFields, t);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasSubmitted(true);
    const isValid = validateForm();
    // console.log("Form Errors:", errors);
    if (!isValid) return;

    setLoading(true);

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      termsOfUse: values.termsOfUse,
      specificApproval: values.specificApproval,
      privacyConsent: values.privacyConsent,
      firstPartyMarketingConsent: values.firstPartyMarketingConsent,
      thirdPartyMarketingConsent: values.thirdPartyMarketingConsent
    };

    try {
      await registerUser({
        payload,
      });

      setIsSuccess(true);
      setAjaxError(null);
    } catch (error) {
      setAjaxError(error.status || t("formErrors.error.registrationGeneric"));
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (!isValid) return;

    setLoading(true);
    try {
      const loginData = await verifyOtpAndLogin(
        {
          email: values.email,
          otp: values.otp,
          password: values.password,
        },
        updateUser
      );
      props.setLoginSuccess(true);
      navigate("/welcome");
    } catch (error) {
      setAjaxError(error.status || t("formErrors.error.OTPGeneric"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="page-title">
            {isSuccess ? t("registration.otp") : t("registration")}
          </h1>
          {isSuccess ? (
            <p className="page-subtitle">{t("messages.msg.otp")}</p>
          ):(
            <p className="page-subtitle">{t("registration.disclaimer")}</p>
          )}
          {isSuccess ? (
            <Form
              className="otpForm"
              noValidate
              validated={validated}
              onSubmit={handleOtpSubmit}
            >
              <Form.Group controlId="otp" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("formLabels.label.otp")}
                  name="otp"
                  value={values.otp || ""}
                  onChange={(e) => handleFieldChange("otp", e.target.value)}
                  required
                  isInvalid={!!errors.otp}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.otp}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={loading}>
                {loading ? t("forms.loading") : t("forms.submit")}
              </Button>
              {ajaxError && (
                <p className="text-danger page-subtitle">{ajaxError}</p>
              )}
            </Form>
          ) : (
            <Form
              className="registrationForm"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="name" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("formLabels.label.firstName")}
                  name="firstName"
                  value={values.firstName}
                  onChange={(e) =>
                    handleFieldChange("firstName", e.target.value)
                  }
                  required
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="lastName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("formLabels.label.lastName")}
                  name="lastName"
                  value={values.lastName}
                  onChange={(e) =>
                    handleFieldChange("lastName", e.target.value)
                  }
                  required
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="email" className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("formLabels.label.email")}
                  value={values.email}
                  onChange={(e) => handleFieldChange("email", e.target.value)}
                  required
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password" className="mb-3 password-field">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder={t("formLabels.label.password")}
                  value={values.password}
                  onChange={(e) =>
                    handleFieldChange("password", e.target.value)
                  }
                  required
                  isInvalid={!!errors.password}
                />
                <Button
                  variant="outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="bi-eye" />
                  ) : (
                    <i className="bi-eye-slash" />
                  )}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="termsOfUse" className="">
                <Form.Check
                  name="termsOfUse"
                  required
                  label={
                    <Trans
                      i18nKey="formLabels.label.termsOfUse"
                      components={{
                        a: <a href={termsAndConditionsDoc} target="_blank" rel="noopener noreferrer" />,
                      }}
                    />
                  }
                  checked={values.termsOfUse || false}
                  onChange={(e) =>
                    handleFieldChange("termsOfUse", e.target.checked)
                  }
                  isInvalid={!!errors.termsOfUse}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.termsOfUse}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="specificApproval">
                <Form.Check
                  name="specificApproval"
                  required
                  label={
                    <Trans
                      i18nKey="formLabels.label.specificApproval"
                      components={{
                        a: <a href={specificApprovalDoc} target="_blank" rel="noopener noreferrer" />,
                      }}
                    />
                  }
                  checked={values.specificApproval || false}
                  onChange={(e) =>
                    handleFieldChange("specificApproval", e.target.checked)
                  }
                  isInvalid={!!errors.specificApproval}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.specificApproval}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="privacyConsent">
                <Form.Check
                  name="privacyConsent"
                  required
                  label={
                    <Trans
                      i18nKey="formLabels.label.privacyConsent"
                      components={{
                        a: <a href={privacyPolicyDoc} target="_blank" rel="noopener noreferrer" />,
                      }}
                    />
                  }
                  checked={values.privacyConsent || false}
                  onChange={(e) =>
                    handleFieldChange("privacyConsent", e.target.checked)
                  }
                  isInvalid={!!errors.privacyConsent}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.privacyConsent}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="firstPartyMarketingConsent">
                <Form.Check
                  name="firstPartyMarketingConsent"
                  required
                  label={t("formLabels.label.firstPartyMarketingConsent")}
                  checked={values.firstPartyMarketingConsent || false}
                  onChange={(e) =>
                    handleFieldChange(
                      "firstPartyMarketingConsent",
                      e.target.checked
                    )
                  }
                  isInvalid={!!errors.firstPartyMarketingConsent}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstPartyMarketingConsent}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="thirdPartyMarketingConsent">
                <Form.Check
                  name="thirdPartyMarketingConsent"
                  required
                  label={t("formLabels.label.thirdPartyMarketingConsent")}
                  checked={values.thirdPartyMarketingConsent || false}
                  onChange={(e) =>
                    handleFieldChange(
                      "thirdPartyMarketingConsent",
                      e.target.checked
                    )
                  }
                  isInvalid={!!errors.thirdPartyMarketingConsent}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.thirdPartyMarketingConsent}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={loading}>
                {loading ? t("forms.loading") : t("forms.submit")}
              </Button>
              {ajaxError && (
                <p className="text-danger page-subtitle">{ajaxError}</p>
              )}
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Registration;
