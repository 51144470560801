import "../styles/Scan.scss";

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setWearableId, patchWearableId } from "../utils/api";

import Loader from "./Loader";
import QrScanner from "qr-scanner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Scan(props) {
  const { t } = props;
  const navigate = useNavigate();

  const videoEl = useRef(null);

  const onScanSuccess = async (result) => {
    const wearableId = result.data;
    // console.log('******* scanner id', wearableId);
    if (wearableId) {
      try {
        await setWearableId(wearableId);
        await patchWearableId(wearableId);
        props.setScanResult(wearableId);
      } catch (error) {
        // console.log('******* scanner error', error);
        props.setShowScanErrorModal(true);
      } finally {
        props.scanner?.current?.stop();
        navigate("/welcome");
      }
    }
  };
  const onScanFail = (error) => {
    // console.log('_____ scanner none', error);
  };

  useEffect(() => {
    if (videoEl?.current) {
      props.scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
      });
      props.scanner?.current?.start()
        .then(
          // console.log('_____ scanner start')
        ).catch((err) => {
          // console.log('_____ scanner error', err);
        });
    }
  });

  // useEffect(() => {
  //   if (props.scanner?.current) props.scanner?.current?.stop()
  //   if (props.scanResult) navigate("/welcome");
  // }, [props.scanResult]);

  return (
    <>
      <div className="qr-reader">
        {/* <Loader />  */}
        <video preload="none" ref={videoEl}></video>
      </div>
      <Container fluid>
        <Row>
          <Col>
            <div className="scan-me">
              {t("messages.msg.scan")}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Scan;
