import "../styles/Games.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGame } from "../context/gameContext";
import Cookies from 'js-cookie';
import { getGameStatus, setGameStatus } from "../utils/api";
import { getGamingScore } from "../utils/gaming";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";

import { ReactComponent as IconBadge1} from "../assets/BADGE_1.svg";
import { ReactComponent as IconBadge2} from "../assets/BADGE_2.svg";
import { ReactComponent as IconBadge3} from "../assets/BADGE_3.svg";
import { ReactComponent as IconBadge4} from "../assets/BADGE_4.svg";
import { ReactComponent as IconBadge5} from "../assets/BADGE_5.svg";

import ligafanImage from "../assets/LIGAFANGAME_image.jpg";

function LigaFan(props) {
  const { t } = props;
  const navigate = useNavigate();
  const { games, updateGames, gamePrizes, updateGamePrizes } = useGame();

  // const gameSolution = ["1", "3", "2", "2", "2", "2", "1", "3", "3", "4"];
  // const gameSolution = [1, 3, 2, 2, 2, 2, 1, 3, 3, 4];
  // const gameSolution = window.atob("MSwzLDIsMiwyLDIsMSwzLDMsNA==").split(',').map((item) => {
  //   return parseInt(item);
  // })
  const gameSteps = 10;

  const [gameScore, setGameScore] = useState();
  const [gameChoice, setGameChoice] = useState(null);

  const [gameCover, setGameCover] = useState(false);
  const [gamePlay, setGamePlay] = useState();

  const updateGamePlay = (newGamePlay) => {
    setGamePlay(newGamePlay);
  };

  const handleChoice = (answer) => {
    setGameChoice(answer);
    // console.log("______ gameChoice", answer);
  };

  const handleStart = () => {
    setGameCover(false);
  };

  const handleNext = () => {
    if (gameChoice) {
      const progress = [...gamePlay, parseInt(gameChoice)];
      updateGamePlay(progress);
      setGameStatus('ligafan', Cookies.get('campovolo_email'), progress, updateGames);
      setGameChoice(null);
    }
  };

  const handleExit = () => {
    setGameChoice(null);
    navigate("/welcome");
  };

  useEffect(() => {
    // console.log("////// load games", games);
    if (games) {
      const gameSet = games.filter(gameMatch => gameMatch.gameId === "ligafan")[0];
      // console.log("////// this game is", gameSet);
      if (gameSet?.gamePoints) {
        // console.log("////// this game is at", gameSet?.gamePoints.length);
        setGameCover(gameSet.gamePoints?.length === 0 ? true : false);
        updateGamePlay(gameSet?.gamePoints);
      } else {
        setGameCover(true);
        updateGamePlay([]);
      }
    } else {
      setGameCover(true);
      updateGamePlay([]);
    }
  }, [games]);

  useEffect(() => {
    // console.log("______ gamePlay", gamePlay);
    if (gamePlay?.length === gameSteps) {
      const gamingScore = getGamingScore('ligafan', gamePlay);
      setGameScore(gamingScore);
    }
  }, [gamePlay]);

  useEffect(() => {
    if(gameScore) updateGamePrizes("ligafan", gameScore);
  }, [gameScore]);

  // useEffect(() => {
  //   console.log("////// refresh", games);
  //   const refresh = async () => {
  //     await getGameStatus(updateGames);
  //     props.setIsLoading(false);
  //   };
  
  //   props.setIsLoading(true);
  //   refresh();
  // }, []);

  return (
    <>
      <Container fluid className="game">
        <Row>
          <Col>
            {gamePlay?.length === gameSteps ? (
              <>
                <div className="game-mirror invert">
                  <h2>{t("game.ligafan.title")}</h2>
                    <div className="prize-congrats">{t("game.ligafan.congrats")}</div>
                    <div className="prize-congrats-sub">{t("game.ligafan.congrats.sub")}</div>
                    { 
                      (gameScore === "0.20" && 
                        <>
                          <i className="prize" style={{color: "#e30f6f"}}><IconBadge1 /></i>
                          <div className="prize-name" style={{color: "#e30f6f"}}>{t("game.ligafan.prize.0.20.name")}</div>
                          <div className="prize-detail">{t("game.ligafan.prize.0.20.detail")}</div>
                        </>
                      ) || (gameScore === "20.40" &&
                        <>
                          <i className="prize" style={{color: "#0a71b3"}}><IconBadge2 /></i>
                          <div className="prize-name" style={{color: "#0a71b3"}}>{t("game.ligafan.prize.20.40.name")}</div>
                          <div className="prize-detail">{t("game.ligafan.prize.20.40.detail")}</div>
                        </>
                      ) || (gameScore === "40.60" && 
                        <>
                          <i className="prize" style={{color: "#ec7703"}}><IconBadge3 /></i>
                          <div className="prize-name" style={{color: "#ec7703"}}>{t("game.ligafan.prize.40.60.name")}</div>
                          <div className="prize-detail">{t("game.ligafan.prize.40.60.detail")}</div>
                        </>
                      ) || (gameScore === "60.80" && 
                        <>
                          <i className="prize" style={{color: "#00892e"}}><IconBadge4 /></i>
                          <div className="prize-name" style={{color: "#00892e"}}>{t("game.ligafan.prize.60.80.name")}</div>
                          <div className="prize-detail">{t("game.ligafan.prize.60.80.detail")}</div>
                        </>
                      ) || (gameScore === "80.100" && 
                        <>
                          <i className="prize" style={{color: "#e1001a"}}><IconBadge5 /></i>
                          <div className="prize-name" style={{color: "#e1001a"}}>{t("game.ligafan.prize.80.100.name")}</div>
                          <div className="prize-detail">{t("game.ligafan.prize.80.100.detail")}</div>
                        </>
                      )
                    }
                </div>
                <Button variant="primary" onClick={handleExit}>
                  {/* <i className="bi bi-caret-left" /> */}
                  {t("game.cta.back")}
                </Button>
              </>
            ) : (
              gameCover ? (
                <>
                  <div className="game-mirror invert">
                    <h2 className="bigger">{t("game.ligafan.title")}</h2>
                    <img loading="lazy" src={ligafanImage} alt="Liga Fan Game" />
                    {/* <div className="game-subtitle">{t("game.ligafan.subtitle")}</div> */}
                    <div className="game-description">{t("game.ligafan.description")}</div>
                  </div>
                  <Button variant="primary" onClick={handleStart}>
                    <i className="bi bi-caret-right" />
                    {t("game.cta.start")}
                  </Button>
                </>
              ) : (
                <>
                  <div className="game-mirror invert">
                    <h2>{t("game.ligafan.title")}</h2>
                      <ProgressBar
                        now={(gamePlay?.length + 1) * 10}
                      />
                      <small>
                        {gamePlay?.length + 1}/{gameSteps}
                      </small>
                      {Array(gameSteps).fill().map((key, index) => {
                        const part = index + 1;
                        return (
                          <>
                            <p
                              className={
                                gamePlay?.length === index &&
                                "todo"
                              }
                            >
                              {t("game.ligafan.part." + part + ".question")}
                            </p>
                            <div
                              id={"game-part-" + part}
                              key={"game-part-" + part}
                              className="game-form"
                            >
                              <Form key={"game-part-" + part + "-form"}>
                                <Form.Check
                                  label={t(
                                    "game.ligafan.part." + part + ".answer.1"
                                  )}
                                  name={"game-part-" + part + "-answers"}
                                  type="radio"
                                  value="1"
                                  id={"game-part-" + part + "-answer-1"}
                                  key={"game-part-" + part + "-answer-1"}
                                  onChange={(e) => handleChoice(e.target.value)}
                                />
                                <Form.Check
                                  label={t(
                                    "game.ligafan.part." + part + ".answer.2"
                                  )}
                                  name={"game-part-" + part + "-answers"}
                                  type="radio"
                                  value="2"
                                  id={"game-part-" + part + "-answer-2"}
                                  key={"game-part-" + part + "-answer-2"}
                                  onChange={(e) => handleChoice(e.target.value)}
                                />
                                <Form.Check
                                  label={t(
                                    "game.ligafan.part." + part + ".answer.3"
                                  )}
                                  name={"game-part-" + part + "-answers"}
                                  type="radio"
                                  value="3"
                                  id={"game-part-" + part + "-answer-3"}
                                  key={"game-part-" + part + "-answer-3"}
                                  onChange={(e) => handleChoice(e.target.value)}
                                />
                                <Form.Check
                                  label={t(
                                    "game.ligafan.part." + part + ".answer.4"
                                  )}
                                  name={"game-part-" + part + "-answers"}
                                  type="radio"
                                  value="4"
                                  id={"game-part-" + part + "-answer-4"}
                                  key={"game-part-" + part + "-answer-4"}
                                  onChange={(e) => handleChoice(e.target.value)}
                                />
                              </Form>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <Button variant="primary" onClick={handleNext}>
                    <i className="bi bi-chevron-right" />
                    {(gamePlay?.length + 1) === gameSteps ? t("game.cta.end") : t("game.cta.next")}
                  </Button>
                </>
              )
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LigaFan;
