import { useState } from "react";
import validationUtils from "../utils/validationUtils.js";

const useFormValidation = (initialFields, t) => {

  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleFieldChange = (field, value) => {
    setFields((prev) => ({ ...prev, [field]: value }));
    if (hasSubmitted) {
      const fieldError = validationUtils.findFieldError(field, value, t);
      setErrors((prev) => ({ ...prev, [field]: fieldError[field] || null }));
    }
  };

  const validateForm = () => {
    const formErrors = validationUtils.findFormErrors(fields, t);
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return { values: fields, setFields, errors, setErrors, handleFieldChange, validateForm, setHasSubmitted };
};

export default useFormValidation;