import axios from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_PROD,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiClientHub = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_HUB_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthCookies = (data) => {
  // console.log("______ setAuthCookies token", data.token);
  Cookies.set("campovolo_email", data.email, { expires: 1, secure: true });
  Cookies.set("campovolo_token", data.token, { expires: 1, secure: true });
  Cookies.set("campovolo_refreshToken", data.refreshToken, {
    expires: 1,
    secure: true,
  });
};

const removeAuthCookies = () => {
  Cookies.remove('campovolo_email', { expires: 1, secure: true });
  Cookies.remove('campovolo_token', { expires: 1, secure: true });
  Cookies.remove('campovolo_refreshToken', { expires: 1, secure: true });
  // console.log("______ user is removed");
};

export const registerUser = async (userData) => {
  try {
    const response = await apiClient.post(
      "/users/registration/common",
      userData.payload
    );
    return response.data;
  } catch (error) {
    // console.log("______ registerUser error", error);
    throw error.response?.data || { status: "Error during registration" };
  }
};

export const verifyOtpAndLogin = async (
  otpData,
  updateUser,
  forgotPassword
) => {
  let otpResponse = "";
  try {
    // Verifica OTP
    if (forgotPassword) {
      const OTPpayload = {
        email: otpData.email,
        token: otpData.token,
        newPassword: otpData.newPassword,
        appType: process.env.REACT_APP_TYPE,
      };
      otpResponse = await apiClient.post(
        "/auth/validateForgotPass",
        OTPpayload
      );
    } else {
      const OTPpayload = {
        email: otpData.email,
        otp: otpData.otp,
        appType: process.env.REACT_APP_TYPE,
      };
      otpResponse = await apiClient.post("/users/validateEmailOTP", OTPpayload);
    }
    if (otpResponse.status === 200) {
      // Se l'OTP è valido, procedi con il login automatico
      const loginPayload = {
        email: otpData.email,
        password: forgotPassword ? otpData.newPassword : otpData.password,
        appType: process.env.REACT_APP_TYPE,
      };
      const loginResponse = await apiClient.post(
        "/auth/login/v2",
        loginPayload
      );

      const authCookieData = {
        email: otpData.email,
        token: loginResponse.data.token,
        refreshToken: loginResponse.data.refreshToken,
      };
      setAuthCookies(authCookieData);
      updateUser({
        firstName: loginResponse.data.firstName,
        lastName: loginResponse.data.lastName,
        nickname: loginResponse.data.nickname,
        phoneNumber: loginResponse.data.phoneNumber,
        id: loginResponse.data.userId,
      });

      return loginResponse.data;
    }
  } catch (error) {
    // console.log("______ verifyOtpAndLogin error", error);
    throw error.response?.data || { status: "OTP verification or login error" };
  }
};

export const loginUser = async (loginData, updateUser) => {
  try {
    const loginPayload = {
      email: loginData.email,
      password: loginData.password,
      appType: process.env.REACT_APP_TYPE,
    };
    const response = await apiClient.post("/auth/login/v2", loginPayload);
    const authCookieData = {
      email: loginData.email,
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    };
    setAuthCookies(authCookieData);
    updateUser({
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      nickname: response.data.nickname,
      id: response.data.userId,
    });
    return response.data;
  } catch (error) {
    // console.log("______ loginUser error", error);
    throw error.response?.data || { status: "Login error" };
  }
};

export const forgotPassword = async (loginData) => {
  try {
    const forgotPasswordPayload = {
      email: loginData.email,
      appType: process.env.REACT_APP_TYPE,
    };
    const response = await apiClient.post(
      "/auth/forgotPass",
      forgotPasswordPayload
    );

    return response.data;
  } catch (error) {
    // console.log("______ forgotPassword error", error);
    throw error.response?.data || { status: "Forgot Password error" };
  }
};

export const checkTokenAx = async () => {
  const email = Cookies.get("campovolo_email");
  const refreshToken = Cookies.get("campovolo_refreshToken");
  // const refreshToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJsb3JlbnpvLmxhenphcmlAYWNjZW50dXJlLmNvbSIsInRlbmFudC1pZCI6IjI5NSIsImFwcC10eXBlIjoiQ09OQ0VSVCIsImp0aSI6ImU4MzllYWQzLTU2MWItNDY3OS1iMTU1LTIxNjU2NWI2YTk5NCIsImV4cCI6MTczMDgzMDAyMSwiaWF0IjoxNzMwNzQzNjIxLCJpc3MiOiJtb2JpbGVhcHAiLCJhdWQiOiJodHRwOi8vdGlja2V0aW5nLmhpZGdsb2JhbC5jb20vIn0.yL-97il1vSktuBid7iN4l86wIsSVkgVxe1RwNHWrNvugmlvew6rknjYExsCNzZLO2HwbtbxhMgq-C-oCPwV8Pg";
  // console.log("______ checkTokenAx", email, refreshToken);
  const payload = {
    appType: process.env.REACT_APP_TYPE,
    email: email,
    refreshToken: refreshToken,
  };
  try {
    const response = await apiClient.post("/auth/refreshToken", payload);
    const authCookieData = {
      email: email,
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    };
    setAuthCookies(authCookieData);
    return response.data;
  } catch (error) {
    // console.log("______ checkTokenAx error", error);
    // throw error.response?.data || { status: 'checkTokenAx error' };
    removeAuthCookies();
  }
};

export const getUserDetailsAx = async (updateUser) => {
  const token = Cookies.get("campovolo_token");
  // console.log("______ getUserDetailsAx token", token);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await apiClient.get("/users/details", config);
    updateUser({
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      nickname: response.data.nickName,
      email: response.data.email,
      prefLanguage: response.data.prefLanguage,
      termsOfUse: response.data.termsOfUse,
      specificApproval: response.data.specificApproval,
      privacyConsent: response.data.privacyConsent,
      firstPartyMarketingConsent: response.data.firstPartyMarketingConsent,
      thirdPartyMarketingConsent: response.data.thirdPartyMarketingConsent,
      id: response.data.userId,
      phoneNumber: response.data.phoneNumber
    });
    return response.data;
  } catch (error) {
    // console.log("______ getUserDetailsAx error", error);
    // throw error.response?.data || { status: "getUserDetailsAx error" };
  }
};

export const getGameStatus = async (updateGame) => {
  const token = Cookies.get('campovolo_token');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClientHub.get('/game-status', config);
    // console.log("______ gameStatus response", response.data?.gameStatus);
    updateGame(response.data?.gameStatus);
    return response.data;
  } catch (error) {
    // throw error.response?.data || { status: 'gameStatus error' };
  }
};

export const deleteGame = async (gameIds) => {
  const token = Cookies.get("campovolo_token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    data: gameIds
  };
  try {
    const response = await apiClientHub.delete('/game-status', config);
    return response.data;
  } catch (error) {
    // throw error.response?.data || { status: 'gameStatus error' };
  }
};

export const logoutUser = async () => {
  const token = Cookies.get('campovolo_token');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClient.post('/auth/logout/v2', {}, config);
    // console.log("______ logoutUser response", response.data);
    removeAuthCookies();
    return response.data;
  } catch (error) {
    // console.log("______ logoutUser error", error);
    // throw error.response?.data || { status: 'gameStatus error' };
  }
};

export const setGameStatus = async (gameId, id, points, updateGame) => {
  const token = Cookies.get('campovolo_token');
  // console.log("______ gameStatus token", token);
  const data = [
    {
        "gameId": gameId,
        "userId": id,
        "gamePoints": points
    }
  ];
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClientHub.patch('/game-status', data, config);
    // console.log("______ setGameStatus response", response.data);
    if (response.data?.gameStatus) {
      updateGame(response.data?.gameStatus);
    } else {
      await getGameStatus(updateGame)
    }
    return response.data;
  } catch (error) {
    // console.log("______ setGameStatus error", error);
    // throw error.response?.data || { status: 'gameStatus error' };
  }
};

export const setWearableId = async (id) => {
  const token = Cookies.get('campovolo_token');
  const data = { "uid": id };
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClientHub.post('/wallet-link', data, config);
    // console.log("______ setWearableId response", response.data?.uid);
    return response.data;
  } catch (error) {
    // console.log("______ setWearableId error", error);
    // throw error.response?.data || { status: 'post wallet-link error' };
  }
};

export const patchWearableId = async (id) => {
  const token = Cookies.get('campovolo_token');
  const data = { "uid": id };
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClientHub.patch('/wallet-link', data, config);
    // console.log("______ setWearableId response", response.data?.uid);
    return response.data;
  } catch (error) {
    // console.log("______ setWearableId error", error);
    throw error.response?.data || { status: 'patch wallet-link error' };
  }
};

export const getWearableId = async (setScanResult) => {
  const token = Cookies.get('campovolo_token');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClientHub.get('/wallet-link', config);
    // console.log("______ getWearableId response", response.data?.uid);
    setScanResult(response.data?.uid);
    return response.data;
  } catch (error) {
    // console.log("______ getWearableId error", error);
    // throw error.response?.data || { status: 'getWearableId error' };
  }
};

export const userEdit = async (newUser, updateUser) => {
  const token = Cookies.get('campovolo_token');
  // console.log("______ userEdit", newUser);
  const payload = {
    appType: process.env.REACT_APP_TYPE,
    email: Cookies.get('campovolo_email'),
    ...newUser
  };
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const response = await apiClient.put('/users/editProfile/v2', payload, config);
    // console.log("______ userEdit response", response.data);
    updateUser({
      firstName: payload?.firstName,
      lastName: payload?.lastName,
      nickname: payload?.nickname,
      phoneNumber: payload?.phoneNumber,
      firstPartyMarketingConsent: payload?.firstPartyMarketingConsent,
      thirdPartyMarketingConsent: payload?.thirdPartyMarketingConsent
    });
    return response.data;
  } catch (error) {
    // console.log("______ userEdit error", error);
    throw error.response?.data || { status: 'userEdit error' };
  }
};

export const deleteUser = async () => {
  const token = Cookies.get("campovolo_token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await apiClient.delete("/users", config);
    removeAuthCookies();
    return response.data;
  } catch (error) {
    throw error.response?.data || { status: 'deleteUser error' };
  }
};

export const deleteWearable = async () => {
  const token = Cookies.get("campovolo_token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await apiClientHub.delete('/wallet-link', config);
    return response.data;
  } catch (error) {
    // throw error.response?.data || { status: 'deleteUser error' };
  }
};